import React from 'react'
import Layout from '../components/shared/components/Layout'
import SEO from '../components/Seo'

const privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy Page" />
      <div />
    </Layout>
  )
}

export default privacy
